<template>
  <coupon
    v-if="isShowCoupon"
    v-bind="$attrs"
    :is-new-user="isNewUser"
    :item="coupon"
    :coupon-type="couponType"
    :for-revisit-component="forRevisitComponent"
    :show-new-transportation-way="showNewTransportationWay"
    :show-wraper-img="showWraperImg"
    :theme-config="themeConfig"
    :class="getClass"
  />
</template>

<script>
import coupon from './coupon.vue'
import { souceFormat } from './utils'
export default {
  name: 'CouponItem',
  components: { coupon },
  props: {
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: {
      // 优惠券信息
      type: Object,
      default: () => { },
    },
    couponType: {
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false
    },
    // TR-13844 邮费全免券支持配置可用的运输方式，
    // 仅个人中心券列表（未使用+过期）、下单页券列表（可用+不可用）、下单页买券券列表在coupon-item组件中配置
    showNewTransportationWay: {
      // 券包展示新的交通运输方式
      type: Boolean,
      default: false
    },
    // 自定义主题色
    themeConfig: {
      type: Object,
      default: () => {}
    },
    showWraperImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShowCoupon: false,
    }
  },
  computed: {
    coupon() {
      return this.souceFormat(this.item) || {}
    },
    getClass(){
      const class1 = this.couponType == 'revisit-coupon' && 'is-border-top-color' 
      
      return [class1]
    }
  },
  watch: {
    coupon: {
      handler(val) {
        if (val?.apply_for) {
          this.isShowCoupon = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    souceFormat: souceFormat,
  },
}
</script>

<style lang="less">
.is-saver-coupon.coupon-item-wrapper::before {
  background-color: #F82854;
}
.is-saver-coupon.coupon-item-wrapper {
  // border: 0.5px solid #FEE3ED;
  background: #FFF4F7;
  .head {
    color: #F82854;
    .shiping-coupon-tip {
      background-color: #FFD7E0;
    }
    .head-right {
      border-color: #F82854;
    }
    .coupon-countdown {
      color: #F82854;
    }
    .coupon-countdown__time > span {
      background-color: #F82854;
    }
  }
  .body {
    li {
      color: rgba(128, 26, 48, 0.70);
      &::before {
        background: rgba(128, 26, 48, 0.70);
      }
    }
  }
  .count {
    background-color: #F82854;
  }
  .count::before {
    border-right-color: #F82854;
    border-color: #F82854 transparent transparent;
  }
  .coupon-mid-line {
    &>div {
      background-image: linear-gradient(to right,
          #fecedb 0%,
          #fecedb 50%,
          transparent 50%);
      
    }
  }
}
.coupon-content.new-user {
  .coupon-item-wrapper {
    min-width: 100%;
    max-width:  100%;
    width:  100%;
  }
  
}
.coupon-item-wrapper.is-new-user {
  background: none;
  border: none;
  mask-image: none !important;
  -webkit-mask-image: none !important;
  overflow: inherit;
  .head {
    color: #FA6338;
  }
  &:before {
    display: none;
  }
  .head-row.head,.head{
    padding: 8px 12px 0;
    border: 1px solid #ff825f;
    border-radius: 8px 8px 0 0;
    background: #FFFBF9;
    border-bottom: none;
    /*rw:begin*/
    color: #fc4070;
  }

  .is-new-user-mid-line {
    height: 14px;
    width: 100%;
    background-size: 100%;
    // 防止阴影上来
    position: relative;
    z-index: 999;
  }

  .body {
    border: 1px solid #ff825f;
    border-radius: 0 0 8px 8px;
    background: #FFFBF9;
    border-top: none;
    padding: 0px 12px 8px;
    box-shadow: 0 1px 2px #b38070;
    li {
      text-align: left;
    }
  }

}
.coupon-item-wrapper {
  position: relative;
  margin-bottom: 16px;
  max-width: 450px;
  // min-width: 300px;
  // min-height: 120px;
  background: var(--bgColor, @sui_color_promo_bg);
  mask-position: -6px;
  border-top: 0;
  overflow: hidden;
  .coupon-cycle-border {
    width: 14px;
    height: 14px;
    background-color: var(--borderColor, #FFE2CF);
    border-radius: 50%;
    position: absolute;
    &__left {
      .left(-7px);
    }
    &__right {
      .right(-7px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--themeColor, @sui_color_promo);
    z-index: @zindex-hack;
  }

  .coupon-item__logo {
    width: 105px;
    height: 94px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .count {
    position: absolute;
    .right(0);
    top: 0;
    height: 18px;
    line-height: 18px;
    // width: 26px;
    width: inherit;
    padding: 0 6px;
    text-align: center;
    background-color: var(--themeColor, @sui_color_promo);
    color: #fff;
    z-index: 0;

    /* stylelint-disable-line declaration-property-value-blacklist */
    &::before {
      content: '';
      position: absolute;
      // .right(26px);
      .right(100%);
      top: 0;
      display: inline-block;
      border-width: 9px 4px;
      border-style: solid;
      border-color: transparent;
      border-top-color: var(--themeColor, @sui_color_promo);
      .border-r(4px, var(--themeColor, @sui_color_promo));
    }
  }

  .coupon-mid-line {
    display: flex;
    align-items: center;
    height: 14px;

    div {
      flex: 1;
      height: 2px;
      background-image: linear-gradient(to right,
          var(--midLineColor, #fff) 0%,
          var(--midLineColor, #fff) 50%,
          transparent 50%);
      background-size: 8px 2px;
      background-repeat: repeat-x;
    }
  }

  .head {
    padding: 20px 16px 8px;
    position: relative;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    color: var(--themeColor, @sui_color_promo);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 0.5px solid var(--borderColor, #FFE2CF);
    border-right: 0.5px solid var(--borderColor, #FFE2CF);

    .head-left {
      .margin-r(12px);
      overflow: hidden;
      flex: 1;
      .title {
        font-weight: 700;
        font-family: "SF UI Text";
        font-size: 18px;
      }
      .coupon-sub-title {
        font-family: 'SF UI Text';
        word-break: keep-all;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .head-right {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      // .margin-r(12px);
      .tag-content {
        font-size: 12px;
        line-height: 14px;
        color: @sui_color_highlight;
        text-transform: capitalize;
        font-weight: normal;
        text-align: right;
        margin-top: 5px;
      }
    }

    p {
      font-size: 12px;
      line-height: 16px;
      margin-top: 3px;
      font-weight: normal;
      word-break: break-all;
      font-family: 'SF UI Text';
      .discount-price {
        color: #d53333;
      }

      .discount-price-unreached {
        font-weight: bold;
      }

      &.coupon-item-content {
        .flexbox();
        .space-between();
      }
    }

    .apply-btn,
    .expired-btn {
      min-width: 60px;
    }

    .c-icon-select {
      min-width: 25px;
      border-radius: 50%;
      text-align: center;
      background-color: rgba(34, 34, 34, 0.2);
      color: rgba(34, 34, 34, 0.2);
      display: inline-block;

      .select-icon {
        opacity: 0;
      }
    }

    .icon-selected {
      background-color: rgba(34, 34, 34, 1);

      & when (@IS_RW) {
        background-color: @sui_color_main;
        color: #fff;
      }

      .select-icon {
        opacity: 1;
      }
    }

    .icon-check {
      opacity: 0;
      font-size: 15px;
      line-height: 25px;
      color: white;
      font-weight: 100;
    }

    .icon-selected .icon-check {
      opacity: 1;
    }

    &.unstart {
      opacity: 0.3;
    }

    .coupon-title-warp {
      .flexbox();
      .align-center();
      flex-wrap: wrap;
    }

    .coupon-stackable {
      background: @sui_color_note_light_bg;
      color: @sui_color_link;
      font-size: 12px;
      font-weight: 400;
      padding: 2px 4px;
    }
  }

  .header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .head-row {
    p {
      font-size: 12px;
    }
  }

  .body {
    position: relative;
    transition: all 0.2s;
    padding: 7px 16px 12px;
    border-left: 0.5px solid var(--borderColor, #FFE2CF);
    border-right: 0.5px solid var(--borderColor, #FFE2CF);
    border-bottom: 0.5px solid var(--borderColor, #FFE2CF);

    &.expand {
      max-height: initial;

      .coupon-desc-content,
      p {
        max-height: initial;
      }

      li .over-text {
        display: block;
      }
    }

    .coupon-desc-content {
      width: 96%;
      overflow: hidden;
      font-family: "SF UI Display";
      font-size: 12px;
      font-weight: 400;
    }
    
    .mult-height {
      max-height: 50px;
    }
    .count-height {
      max-height: 54px;
    }
    .count-height__mult {
      max-height: 52px;
    }

    .tag-tip {
      position: absolute;
      .right(0);
      top: -40px;
      font-size: 12px;
      line-height: 16px;
      color: #d53333;
    }

    .c-icon-tip {
      position: absolute;
      .right(8px);
      bottom: 10px;
      padding: 0;
      color: #999;

      span {
        text-decoration: none;
      }
    }

    .mult-tips {
      bottom: 10px;
    }

    li {
      font-size: 12px;
      line-height: 14px;
      color: #666;
      .padding-l(7px);
      position: relative;

      //max-height: 14px;
      //overflow: hidden;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //max-height: 28px;
        max-width: 95%;
        line-height: 1.5;
      }

      &::before {
        content: '';
        position: absolute;
        .left(0);
        .margin-l(-1.5px);
        top: 5.5px;
        height: 6px;
        width: 6px;
        background: #666;
        transform: scale(0.5);
        transform-origin: 50% 0;
        border-radius: 50%;
      }
    }

    li:not(:first-child) {
      margin-top: 4px;
    }

    .over-text {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .countdown-range {
      &:before {
        top: 8px;
        background: #FA6338;
        /*rw:begin*/
        background: #666666;
      }
    }
  }

  &.prime-coupon {
    background: linear-gradient(0deg, #ffe2d3, #ffe2d3), #ffffff;
    border: none;
    border-radius: 5px;

    &:before {
      height: 4px;
      background-color: #da7e50;
      border-radius: 5px 5px 0 0;
    }

    .coupon-mid-line {
      div {
        background-image: linear-gradient(to right,
            rgba(135, 60, 0, .6) 0%,
            rgba(135, 60, 0, .6) 50%,
            transparent 50%);
      }
    }

    .count {
      background-color: #da7e50;
      border-radius: 0 4px 0 0;

      &:before {
        border-color: #da7e50 transparent transparent;
        border-right: 4px solid #da7e50;
      }
    }

    .head {
      color: rgba(135, 60, 0, 1);
    }

    .body {
      li {
        color: rgba(135, 60, 0, 0.6);

        &:before {
          background-color: rgba(135, 60, 0, 0.6);
        }
      }
    }
  }

  &.prime-coupon__max {
    &:before {
      background-color: #ff7f46;
    }

    .max-count {
      width: inherit;
      background: linear-gradient(92deg, #ff7f46 3.07%, #ff345b 99.91%);
      color: #ffe2d3;
      font-size: 12px;
      // transform: scale(0.9);
      right: 0;
      top: 0;
      padding: 0 6px;
      z-index: 1;

      &:before {
        right: 100%;
        border-color: #ff7f46 transparent transparent;
        .border-r(4px, #FF7F46);
      }
    }

    .head {
      padding: 0;
    }

    .header-wrapper,
    .head-column,
    .head-row {
      padding: 20px 16px 8px;
    }

    .body {
      padding: 0;
    }

    .tips-wrapper {
      width: 100%;
      padding: 0 16px 12px;
    }
  }

  &.prime-coupon_white {
    background: #FFF0E9;
    border-width: .5px;
    border-style: solid;
    border-color: #FFE2CF;
    border-top: 0;
    border-radius: 0;

    &:before {
      border-radius: 0;
    }

    .count {
      border-radius: 0;
    }

    .coupon-mid-line {
      div {
        background-image: linear-gradient(to right,
            rgba(135, 60, 0, .2) 0%,
            rgba(135, 60, 0, .2) 50%,
            transparent 50%);
      }
    }
  }

  &.prime-coupon__logo {
    position: relative;
    border-radius: 3px;

    .shein-club {
      position: absolute;
      padding: 0 6px;
      width: 79.2px;
      height: 15px;
      background-color: #FFE2D3;
      border-bottom-right-radius: 6px;
      top: 0;
      left: -1px;
      z-index: @zindex-hover-tip;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .body {
      li {
        color: #92d28a;

        &:before {
          background-color: #92d28a;
        }
      }
    }
  }

  &.expired {
    border-color: rgba(204, 204, 204, 0.16);
    background-color: rgba(204, 204, 204, 0.12);
    filter: grayscale(100%);

    &:before when (@IS_SH) {
      background-color: #ccc;
    }

    &:before when (@IS_RW) {
      background-color: #e5e5e5;
    }

    .head-right {
      border-color: #ccc !important;
    }

    .count {
      & when(@IS_SH) {
        background: #ccc;
        color: white;
      }

      & when(@IS_RW) {
        background: #e5e5e5;
        color: #999;
      }

      &::before {
        border-top-color: #ccc;
        .border-r(4px, #ccc);

        & when(@IS_RW) {
          border-top-color: #e5e5e5;
          .border-r(4px, #e5e5e5);
        }
      }
    }

    .head {
      color: #bbb;

      .expired-btn {
        background-color: #e5e5e5;
        color: #666;
        border: none;
        font-weight: 400;
        text-transform: initial;
        pointer-events: none;
      }
    }

    .line::before,
    .line::after {
      border-bottom: 1px rgba(204, 204, 204, 0.16) solid;
      .border-r(1px, rgba(204, 204, 204, 0.16));
    }

    .body {

      li,
      .c-icon-tip {
        color: #bbb;
      }

      li::before {
        background-color: #bbb;
      }
    }

    .coupon-mid-line {
      div {
        background-image: linear-gradient(to right,
            #e5e5e5 0%,
            #e5e5e5 50%,
            transparent 50%);
      }
    }

    .shiping-coupon-tip {
      background-color: #BBBBBB;
      color: #fff;
    }
  }

  .bg-wrapper {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .coupon__newly-get {
    position: absolute;
    .left(-38px);
    top: 6px;
    z-index: 1;
    width: 100px;
    height: 17px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(12, 62, 16, 0.12);
    color: #fff;
    background-color: #3CBD45;
    transform: rotate(-45deg) scale(.8333);
    /*rw:begin*/
    color: #222;
    font-weight: 700;
    background-color: #83FFCC;
  }

  &.coupon-for-prime {
    border: 0.5px solid #FFE2CF;
    background: linear-gradient(270deg, #FFF0E8 0%, #FFF 70.69%);

    &:before {
      height: 4px;
      background-color: #C96E3F;
    }

    .head {
      color: #873C00;
      .coupon-countdown {
        color: #873C00;
      }
      .coupon-countdown__time  {
        margin-top: 4px;
        & > span {
          border-radius: 2px;
          border: 0.5px solid #873C00;
          background-color: #873C00;
          height: 16px;
          line-height: 16px;
        }
      }
    }

    .coupon-mid-line > div {
      background-image: linear-gradient(to right,
          #fff 0%,
          #fff 50%,
          transparent 50%);
    }

    .body li {
      color: #873C00;
      opacity: .7;
      &:before {
        background: #873C00;
      }
    }

    .count {
      background-color: #C96E3F;

      &:before {
        border-color: #C96E3F transparent transparent;
        border-right: 4px solid #C96E3F;
      }
    }
  }

  &.coupon-for-saver {
    border: 0.5px solid #FEE3ED;

    &:before {
      height: 4px;
      background-color: #F82854;
    }

    .head {
      color: #F82854;
      .coupon-countdown {
        color: #F82854;
      }
      .coupon-countdown__time  {
        margin-top: 4px;
        & > span {
          border-radius: 2px;
          border: 0.5px solid #F82854;
          background-color: #F82854;
          height: 16px;
          line-height: 16px;
        }
      }
    }

    .coupon-mid-line > div {
      background-image: linear-gradient(to right,
          #fff 0%,
          #fff 50%,
          transparent 50%);
    }

    .body li {
      color: #801A30;
      opacity: .7;
      &:before {
        background: #801A30;
      }
    }

    .count {
      background-color: #F82854;

      &:before {
        border-right-color: #F82854;
        border-color: #F82854 transparent transparent;
      }
    }
  }

}

.is-border-top-color{
  font-family: 'SF UI Display';
  // color: @sui_color_promo;
  /*sh:begin*/
  color: #FA6338;
  /*rw:begin*/
  color: #FC4070;
}
.is-border-top-color::before {
  height: 4px;
  // background-color: @sui_color_promo;
  /*sh:begin*/
  background-color: #FA6338;
  /*rw:begin*/
  background-color: #FC4070;
}

// 膨胀样式
.coupon-item-wrapper {
  &.is-expand-coupon {
    background-color: #FFF3F9;
    &:before {
      background-color: #FA3867;
    }
    .coupon-cycle-border {
      background-color: #FFCFCF;
    }
    .head {
      color: #FA3867;
    }
    .head,
    .coupon-mid-line,
    .body {
      border-left: 0.5px solid #FFCFCF;
      border-right: 0.5px solid #FFCFCF;
    }
    .body {
      border-bottom: 0.5px solid #FFCFCF;
    }
  }
}

.coupon-item-wrapper.hide-coupon-header {
  border-top: 0.5px solid var(--borderColor, #FFE2CF);
  &:before {
    display: none;
  }
}
</style>
