<template>
  <div
    class="head-column"
    :class="{ head: true, noBtn: item.is_bind === 0 }"
  >
    <div class="head-column_top">
      <div class="head-left">
        <template
          v-for="(rule, index) in item.coupon_rule"
          :key="`coupon-rule-${index}`"
        >
          <div class="sub-item">
            <coupon-sub-title
              :is-new-user="isNewUser"
              :item="item"
              :coupon-rule="rule"
              :language="language"
              :index="index"
              :is-satisfied-range="isSatisfiedRange"
              :is-show-free-gift="isShowFreeGift"
              :direction-type="directionType"
              :coupon-type="couponType"
              v-bind="$attrs"
              :is-new-style="isNewStyle"
            />
          </div>
        </template>
      </div>

      <div 
        v-if="isShow"
        ref="headRightRef"
        class="head-right"  
      >
        <slot name="handle"></slot>
        <time-tips 
          v-if="showTimeTips" 
          :item="item" 
          :page="page"
          v-bind="$attrs"
          :language="language" 
          :coupon-type="couponType" 
        />
      </div>
    </div>

    <!-- 优惠券信息 -->
    <slot name="main"></slot>

    <div class="head-column__labels">
      <!-- 下单页可叠加标识（多档券） -->
      <span v-if="item.coupon_rule.length > 1 && stageable">
        {{ language.SHEIN_KEY_PC_22135 }}
      </span>
    </div>

    <coupon-categories
      v-if="isNewStyle && item.category_name.length"
      class="head-column__categories"
      :item="item" 
    />
  </div>
</template>

<script>
import timeTips from '../timeTips.vue'
import couponSubTitle from './couponSubTitle.vue'
import couponCategories from './couponCategories.vue'
export default {
  name: 'HeadColumn',
  components: { timeTips, couponSubTitle, couponCategories },
  props: {
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    isSatisfiedRange: {
      type: Boolean,
      default: false,
    },
    isShowFreeGift: {
      type: Boolean,
      default: true,
    },
    directionType: {
      // 多档券排列顺序
      type: String,
      default: 'column',
    },
    showCoupondown: {
      type: Boolean,
      default: false
    },
    isShowCountdownFlag: {
      // 是否展示倒计时标识
      type: Boolean,
      default: false,
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false,
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    stageable: {
      // 是否可叠加
      type: Boolean,
      default: false,
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: true,
    }
  },
  computed: {
    showTimeTips() {
      // 店铺页未开始券
      if (this.item.timeStatus === '1' && this.page === 'userCoupon') {
        return !this.isShowCountdownFlag
      }
      // 倒计时优先级更高
      return (!this.showCoupondown || !this.forRevisitComponent) && !this.isShowCountdownFlag
    }
  },
  mounted() {
    const clientWidth = this.$refs.headRightRef.clientWidth
    if (clientWidth == 15) {
      this.isShow = false
    }
  },
}
</script>

<style lang="less">
.head-column {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .head-column_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sub-item {
      margin-bottom: 16px;
    }
  }
  .head-right {
    max-width: 50%;
    .padding-l(15px);
    height: 80px;
    justify-content: center;
    .border-l-type(1px, @sui_color_promo, dashed);
  }

  &__labels {
    align-self: flex-start;
    > span {
      font-family: SF UI Display;
      font-size: 10px;
      padding: 0 4px;
      border-radius: 1px;
      border: 1px solid rgba(51, 126, 255, 0.2);
      line-height: 16px;
      font-weight: 400;
      white-space: nowrap;
      background-color: @sui_color_note_light_bg;
      color: @sui_color_link;
    }
  }

  &__categories {
    align-self: flex-start;
  }
}
.head-column.noBtn{
   .head-right {
     border-left: none;
   }
}

.expired {
  .head-right {
    border-color: #ccc;
  }
}
</style>
