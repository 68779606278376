<template>
  <!-- 优惠券操作 -->
  <section class="btn-operation">
    <!-- 膨胀劵A 样式 -->
    <div
      v-if="showExpandBtn && expandAbtInfo?.CodeExpandType === 'A' "
      class="coupon-expansion-btn"
      :style="{
        backgroundImage: `url(${PUBLIC_CDN}/she_dist/images/coupon/${
          GB_cssRight ? 'expand-coupon-btn-ar-681a017298' : 'expand-coupon-btn-d35abbb23c'
        }.svg)`
      }"
      @click="expansionClick()"
    >
      <span
        v-if="item.expanded_coupon_info"
        class="coupon-expansion-btn__badge"
      >
        {{ expandedOffText }}
      </span>
      <span>{{ language.SHEIN_KEY_PC_24982 }}</span>
    </div>

    <!-- 膨胀劵B 样式 -->
    <div
      v-if="showExpandBtn && expandAbtInfo?.CodeExpandType === 'B'"
      class="couppon-expansion-btn-B"
      :style="{
        backgroundImage: `url(${PUBLIC_CDN}/she_dist/images/expansion_coupon/expansion-btn-short-8eb9ac396d.png)`
      }"
      @click="expansionClick()"
    >
      {{ language.SHEIN_KEY_PC_24982 }}
    </div>

    <!-- 膨胀盖章 -->
    <div
      v-show="item.expandStatus == 'seal' && isloaded"
      class="stamp-warpper loaded"
    >
      <div
        class="coupon-stamp"
      >
        <div :style="{ backgroundImage: `url(${PUBLIC_CDN}/she_dist/images/coupon/upgrated-stamp-9b969cd0de.svg)` }">
          <span
            class="scale-font"
            :class="{ 'scale-font__limit': language.SHEIN_KEY_PC_25025 && language.SHEIN_KEY_PC_25025.length > 8 }"
          >
            {{ language.SHEIN_KEY_PC_25025 }}
          </span>
        </div>
      </div>
    </div>

    <!-- 优惠券选择框 -->
    <span
      v-if="showCouponCheckbox"
      class="c-icon-select icon-select-wrap"
      :class="{ 'icon-selected': checked }"
      :style="{display: 'flex', minWidth: '22px'}"
    >
      <Icon
        class="select-icon"
        name="sui_icon_selected_16px"
        color="#FFFFFF"
        size="15px"
      />
    </span>

    <!-- apply按钮 -->
    <s-button
      v-if="isShowApplyBtn"
      v-expose="vExposeBindValue(item)"
      class="apply-btn"
      :type="['primary', 'H28PX']"
      :tabindex="unusedCouponHas ? '0' : '-1'"
      :aria-hidden="unusedCouponHas ? false : true"
      role="tab"
      @click="hanleApply"
    >
      {{ langOfApply }}
    </s-button>


    <!-- expire按钮 -->
    <s-button
      v-if="isExpired"
      class="apply-btn expired-btn"
      :type="['primary', 'H28PX']"
      disabled
      block
      role="tab"
      :tabindex="unusedCouponHas ? '0' : '-1'"
      :aria-hidden="unusedCouponHas ? false : true"
    >
      {{ expiredType == 'used' ? language.SHEIN_KEY_PC_30230 : language.SHEIN_KEY_PC_15099 }}
    </s-button>

    <!-- 领券逻辑 -->
    <!-- 券已领取icon -->
    <div
      v-if="showCouponIcon"
      class="get-coupon-icon"
    >
      <div class="get-coupon-line">
        <div class="get-coupon-dashed">
          <div class="get-coupon-tip">
            {{ language.SHEIN_KEY_PC_22087 }}
          </div>
        </div>
      </div>
    </div>

    <!-- 领券按钮 -->
    <s-button
      v-if="
        item.coupon_status && item.coupon_status == '2' && !isGetCouponSuccess && item.is_bind !== 0
      "
      class="apply-btn"
      :type="['primary', 'H28PX']"
      block
      :disabled="getCouponDisabled"
      @click="getCouponHandle"
    >
      {{ langOfGet }}
    </s-button>

    <!-- 券已领完-->
    <s-button
      v-if="item.coupon_status && item.coupon_status == '3'"
      class="apply-btn"
      :type="['primary', 'H28PX']"
      disabled
      block
    >
      {{ language.SHEIN_KEY_PC_22140 }}
    </s-button>

    <!-- 个人中心券列表-凑单apply按钮 -->
    <s-button
      v-if="isShowAddBtn"
      class="apply-btn"
      :type="['primary', 'H28PX']"
      :tabindex="unusedCouponHas ? '0' : '-1'"
      :aria-hidden="unusedCouponHas ? false : true"
      role="tab"
      @click="hanleAdd"
    >
      {{ language.SHEIN_KEY_PC_17399 }}
    </s-button>

    <coupon-countdown
      v-if="showCouponCountdown"
      :class="{ 'RW-start-soon': item.timeStatus === '1' && page === 'userCoupon' }"
      :language="language"
      :item="item"
      :text="item.timeStatus === '1' ? language.SHEIN_KEY_PC_28588 : ''"
      :direction-type="directionType"
      @countdown-over="countdownOver"
    />

    <!-- 支付成功按钮 -->
    <!-- <s-button
      v-if="isShowPaySuccessBtn"
      :type="['primary', 'H28PX']"
      class="head-row__top-wrapper-btn"
      @click="viewCouponList"
    >
      {{ language.SHEIN_KEY_PC_17703 }} >
    </s-button> -->
  </section>
</template>

<script>
import { bingCouponApi, estimatedBindCouponApi } from './utils'
const { IS_RW, GB_cssRight, PUBLIC_CDN } = gbCommonInfo
import CouponCountdown from './couponCountdown.vue'
import { COUPON_OFF_TYPE } from './constants'
import { Icon } from '@shein-aidc/icon-vue3'

// 是店铺券
const isStoreCoupon = coupon_dimension => Number(coupon_dimension) === 3
// 是自营店铺
const isSelfSupportStore = store_code => Number(store_code) === 1
// 券适用范围是全部商品
const isFullRange = apply_for => Number(apply_for) === 1
// 券适用范围是部分商品
const isPartRange = apply_for => Number(apply_for) === 2

export default {
  name: 'CouponHandle',
  components: {
    CouponCountdown,
    Icon,
  },
  props: {
    expandAbtInfo: {
      type: Object,
      default: () => {}
    },
    item: {
      // 优惠券信息
      type: Object,
      default: () => {},
    },
    language: {
      // 多语言
      type: Object,
      default: () => {},
    },
    isExpired: {
      // 是否失效
      type: Boolean,
      default: false,
    },
    expiredType: {
      type: String,
      default: 'expired',
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    unusedCouponHas: {
      type: Boolean,
      default: false,
    },
    abtCouponApply: {
      // abt控制参数, 是否展示apply按钮, apply和use为凑单按钮
      type: Boolean,
      default: false,
    },
    isLogin: {
      // 是否登录
      type: Boolean,
      default: false,
    },
    isMultMall: {
      // 是否是多mall
      type: Boolean,
      default: true,
    },
    showCouponCheckbox: {
      // 是否展示选中框
      type: Boolean,
      default: false,
    },
    checked: {
      // 是否选中
      type: Boolean,
      default: false,
    },
    isShowCountdownFlag: {
      type: Boolean,
      default: false,
    },
    directionType: {
      // 多档券排列顺序
      type: String,
      default: 'column',
    },
    // isShowPaySuccessBtn: {
    //   // 是否展示支付成功按钮
    //   type: Boolean,
    //   default: false,
    // },
    isloaded: {
      type: Boolean,
      default: false,
    },
    abtParams: {
      type: Object,
      default: () => ({})
    },
    abtEstimated: { // abt控制参数, 是否命中到手价实验
      type: Boolean,
      default: false
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    loginGetCouponCode: {
      type: String,
      default: ''
    },
    // 是否显示会费券的 apply 按钮
    duesCouponApplyVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      IS_RW,
      PUBLIC_CDN,
      getCouponDisabled: false, // loading, 避免多次点击
      isGetCouponSuccess: false, // 是否领取成功
      isGetAgain: false, // 是否再次领取
      isBtn: true, //是否展示膨胀按钮
      GB_cssRight,
    }
  },
  computed: {
    isCouponaddall() {
      return this.abtParams?.Couponaddall?.param?.coupon_add_all_switch == 'on'
    },
    showExpandBtn(){
      return this.item.expandStatus == 'btn' && this.expandAbtInfo?.CodeExpand != 'off'
    },
    // 控制去用券展示逻辑
    isShowApplyBtn() {
      // 会费券 apply 按钮展示判断
      if ([58, 59].includes(+this.item.real_type_id)) return this.duesCouponApplyVisible
      // wiki.pageId=1176264885
      if (this.isCouponaddall) {
        const { timeStatus, start_date, end_date } = this.item
        const current_date = new Date() / 1000
        return ['0', '2', '4'].includes(timeStatus) && start_date < current_date && current_date < end_date
      }
      if (this.item.real_type_id == '53') return false
      let isVerifiState = true
      if (this.item.timeStatus != '') {
        // 是否可用
        isVerifiState = ['0', '2'].includes(this.item.timeStatus)
      }
      // 可展示apply按钮的apply_for类型
      const applyForFlag =
        !['1', '5', '6', '8', '9'].includes(this.item.apply_for) &&
        this.abtCouponApply
      // 店铺券不展示凑单按钮
      const isStoreCoupon = this.item.coupon_dimension != '3'

      // 店铺 ccc 组件弹窗 / 店铺 promo 组件弹窗里的券
      if (this.page === 'store_promotion_coupon' || this.page === 'store') {
        return (
          isVerifiState &&
          !this.isCheckout && 
          !this.isExpired &&
          this.isLogin &&
          Number(this.item.coupon_status) === 1
        )
      }
      return (
        isVerifiState &&
        !this.isExpired &&
        !this.isCheckout &&
        applyForFlag &&
        isStoreCoupon
      )
    },
    showCouponCountdown() {
      // 兼容旧逻辑
      if (this.page !== 'userCoupon') return this.isShowCountdownFlag && this.directionType != 'row'

      // 个人中心券列表未开始券展示倒计时
      return this.isShowCountdownFlag && (this.item.timeStatus === '1' || this.directionType != 'row')
    },
    isShowAddBtn() {
      if (this.isExpired) return
      if (this.isCouponaddall) return
      const pageIsUserCoupon = this.page === 'userCoupon'
      const {
        store: { store_code } = {},
        coupon_dimension,
        apply_for,
      } = this.item
      return (
        pageIsUserCoupon &&
        isStoreCoupon(coupon_dimension) &&
        !isSelfSupportStore(store_code) &&
        (isFullRange(apply_for) || isPartRange(apply_for))
      )
    },
    // apply 多语言
    langOfApply({ language }) {
      // 店铺促销有apply
      return language.SHEIN_KEY_PC_17399
    },
    // get 多语言
    langOfGet({ language }) {
      // 店铺促销有getCoupon
      if (this.page === 'store' || this.page === 'store_promotion_coupon') {
        return language?.SHEIN_KEY_PC_22198
      }
      return language.SHEIN_KEY_PC_22095
    },
    expandedOffText() {
      const { expanded_coupon_info: expandedCouponInfo } = this.item
      if (!expandedCouponInfo) return ''
      const { ruleDimension, rule } = expandedCouponInfo
      const { value } = rule[0]
      if (ruleDimension === COUPON_OFF_TYPE.Cash) {
        return `-${value.amountWithSymbol}`
      }
      const offAmount = value.amount
      return this.language.SHEIN_KEY_PC_27204.replace('{0}', `${+offAmount}%`)
    },
    showCouponIcon() {
      // 店铺弹窗的券，不展示戳印，只展示去使用按钮
      if (this.page === 'store' || this.page === 'store_promotion_coupon') return false
      // 展示戳印逻辑
      return (this.item.coupon_status && this.item.coupon_status == '1') || this.isGetCouponSuccess
    }
  },
  watch: {
    isLogin(val) {
      if (val) {
        if (
          this.isGetAgain 
          || 
          this.item?.couponCode === this.loginGetCouponCode
        ) {
          this.getCouponHandle()
        }
      }
    },
    item: {
      handler() {
        this.isGetAgain = false
        this.isGetCouponSuccess = false
      },
      deep: true,
    },
  },
  methods: {
    // 个人中心-凑单
    hanleAdd() {
      const { store: { store_code } = {}, apply_for } = this.item

      if (isFullRange(apply_for)) {
        location.href = `/store/home?store_code=${store_code}`
      } else {
        // 跳转可用商品弹窗, 去页面内请求数据
        this.$emit('handleApply')
      }
    },
    // 领取优惠券
    async getCouponHandle() {
      if (!this.isLogin) {
        // 判断是否登录
        this.isGetAgain = true
        this.$emit('login-handle')
        return
      }
      // 店铺优惠劵模块
      if (this.page === 'store') {
        this.$emit('click-bind-coupon', this.item)
        return
      }
      const params = {
        coupon_code: this.item?.coupon || '',
        mall_code: this.item?.mall?.mall_code || '',
        store_code: this.item?.store?.store_code || '',
      }
      const newParams = {
        coupon_codes: [this.item?.coupon || '']
      }
      this.getCouponDisabled = true
      if (this.abtEstimated) { // 此处命中到手价实验，调新接口
        const res = await estimatedBindCouponApi(newParams) // 绑定优惠券
        if (res.code == '0') {
          if (res.info?.failureList?.length) {
            // 绑定失败
            const errorCode = res.info.failureList[0].code
            this.$message({ message: this.language[errorCode], type: 'warning' })   
          }
          if (res.info?.successList?.length) {
            // 绑定成功，券状态变为已领取(coupon_status == '1')
            this.$message({
              message: this.language.SHEIN_KEY_PC_22091,
              type: 'success',
            })
            this.isGetCouponSuccess = true
          }
        }
      } else {
        const res = await bingCouponApi(params) // 绑定优惠券
        if (res.code == '0') {
          if (res.info?.failureList?.length) {
            // 绑定失败
            const errorCode = res.info.failureList[0].code
            this.$message({ message: this.language[errorCode], type: 'warning' })
            // 店铺劵已领取状态同步
            const failed = res?.info?.failureList
            const { code } = failed.filter(
              (i) => i.couponCode === this.item?.couponCode
            )[0] || {}
            if (code === '509908') {
              this.isGetCouponSuccess = true
            }
          }
          if (res.info?.successList?.length) {
            // 绑定成功，券状态变为已领取(coupon_status == '1')
            this.$message({
              message: this.language.SHEIN_KEY_PC_22091,
              type: 'success',
            })
            this.isGetCouponSuccess = true
          }
        } else if (this.language?.getFailed) {
          this.$message({ message: this.language.getFailed, type: 'warning' })
        }
      } 
      
      this.getCouponDisabled = false
      this.$emit('get-coupon-handle', {
        code: this.item.coupon, // 券码
        result: this.isGetCouponSuccess, // 领券结果
        status: this.item.coupon_status,
      })
    },
    // 去用券
    hanleApply() {
      this.$emit('send-da-handle')

      // 会费券点击 apply 跳转
      const targetRoute = {
        '58': `/user/prime?productsource=myCouponCenter`,
        '59': `/user/sheinsaver?productsource=myCouponCenter`,
      }[this.item.real_type_id]
      if (targetRoute) {
        window.location.href = targetRoute
        return
      }

      // $emit('send-da-handle') 执行新的用券逻辑
      if (this.isCouponaddall) return

      // 店铺促销活动券
      if (this.item.storeCode && this.page === 'store_promotion_coupon') {
        this.$emit('handleApply', this.item)
        return
      }

      // 店铺弹窗内优惠券
      if (this.page === 'store' || this.page === 'store_promotion_coupon') {
        this.$emit('handleApply', this.item)
        return
      }

      const {
        apply_for,
        category_id = '',
        coupon,
        scId = '',
        coupon_type_id,
        id,
      } = this.item
      const coupon_type = 1
      const catIds = (category_id && category_id.join()) || ''
      const showTip = coupon_type_id == '2' ? 1 : 0
      let [mallCode, couponDimension] = ['', '']
      if (this.isMultMall) {
        mallCode = this.item?.mall?.mall_code || ''
        couponDimension = this.item?.coupon_dimension || ''
      }
      let query = ''
      switch (apply_for) {
        case '2':
          // 跳转可用商品弹窗, 去页面内请求数据
          this.$emit('handleApply')
          break
        case '3':
          // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
          // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
          query = `ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`
          if (couponDimension == '2') {
            query += `&mallCode=${mallCode}`
          }
          sessionStorage.removeItem('couponTipClose') // 删除关闭优惠券tip的标识
          window.open(`/coupon-product.html?${query}`, '_blank')
          break
        case '7':
          // 跳转选品页 scid
          // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
          query = `ShiwTip=1&CouponCode=${coupon.toLowerCase()}`
          if (couponDimension == '2') {
            query += `&mallCode=${mallCode}`
          }
          sessionStorage.removeItem('couponTipClose') // 删除关闭优惠券tip的标识
          window.open(`/coupon-sc-${scId}.html?${query}`, '_blank')
          break
        default:
      }
    },
    countdownOver() {
      this.$emit('countdown-over', this.item.coupon)
    },
    // 点击膨胀按钮
    expansionClick() {
      this.$emit('expansion-click')
    },
      
    viewCouponList () {
      this.$emit('view-coupon-list')
    },
    vExposeBindValue() {
      return {
        id: '1-22-1-12',
        data: {
          tab_type: this.item.timeStatus === '3' ? 2 : 1,
          coupon_id: this.item.coupon,
          coupon_placement: 1,
          is_starting_soon: this.item.timeStatus === '1' ? 1 : 0
        }
      }
    },
  },
  emits: [
    'expansion-click',
    'get-coupon-handle',
    'login-handle',
    'send-da-handle',
    'handleApply',
    'countdown-over',
    'view-coupon-list',
    'click-bind-coupon',
    'getCouponCb'
  ],
}
</script>

<style lang="less">
.btn-operation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .icon-select-wrap {
    display: flex;
    overflow: hidden;
    height: 22px;
    min-width: 22px;
    align-items: center;
    justify-content: center;
  }
  .c-icon-select {
    margin-bottom: 6px;
    cursor: pointer;
  }
  .apply-btn {
    margin-bottom: 6px;
    // --btnColor 在 store_pages/components/CouponDrawer.vue 有定义
    background: var(--btnColor, #000);
    border-color: var(--btnColor, #000);
    border-radius: 0;
    & when (@IS_RW) {
      border-radius: 2px;
    }
  }
  .coupon-countdown__txt {
    color: var(--themeColor, #222);
  }
}
.get-coupon-icon {
  width: 44px;
  height: 44px;
  border: 1px solid var(--themeColor, #FA6338);
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: normal;
  transform: rotateZ(-30deg);
  .get-coupon-line {
    width: 100%;
    height: 100%;
    border: 1px solid var(--themeColor, #FA6338);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .get-coupon-dashed {
      background-color: var(--bgColor, #fff6f3);
      padding: 2px 0;
    }
    .get-coupon-tip {
      background: var(--bgColor, #fff6f3);
      height: 15px;
      border-color: var(--themeColor, #FA6338);
      border-style: solid;
      line-height: 15px;
      border-width: 1px 0 1px 0;
      min-width: 48px;
      text-align: center;
      .text-overflow();
      max-width: 53px;
    }
  }
}

/*膨胀劵B 样式 */
.couppon-expansion-btn-B {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-size: 12px;
  color: #ffffd8;
  font-family: SF UI Display;
  white-space: nowrap;
  font-weight: 700;
  padding: 6.11px 19.42px;
  animation: breathe 0.6s infinite;
}

/*膨胀劵A 样式 */
.coupon-expansion-btn {
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-size: 12px;
  color: #fff;
  font-family: SF UI Display;
  white-space: nowrap;
  font-weight: 700;
  padding: 7.11px 22.42px 5.11px 16.42px;
  animation: breathe 0.7s infinite;
  &__badge {
    position: absolute;
    top: -15px;
    right: -5px;
    padding: 3px 12px;
    font-size: 12px;
    transform: scale(.67);
    transform-origin: 100% 100%;
    line-height: normal;
    font-family: 'SF UI Text';
    font-weight: 600;
    color: #fff;
    background: linear-gradient(90deg, #FF58B2 -0.11%, #FF2525 99.89%);
    border-radius: 11px;
    white-space: nowrap;
  }
}
@keyframes breathe {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(0.99);
  }
}
.stamp-warpper {
  max-width: 52px;
  margin: auto;
  backface-visibility: hidden;
}
.coupon-stamp {
  position: relative;
  > div {
    background-repeat: no-repeat;
    background-size: cover;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-transform: uppercase;
    transform-origin: 50% 50%;
    transform: rotate(-15deg) scale(1);
  }
  .scale-font {
    white-space: nowrap;
    font-size: 12px;
    transform: scale(0.75);
    color: rgba(250, 56, 103, .5);
    font-weight: 700;
    font-family: "SF UI Text";
    &__limit {
      transform: scale(0.5833);
    }
  }
}

.loaded .coupon-stamp > div {
  animation-name: fa;
	animation-duration: 0.4s;
  animation-delay: .1s;
  // transform: rotate(-15deg) scale(1);
}
@keyframes fa {
  0% { opacity: 0.4; transform: rotate(-15deg) scale(5);}
	100% { opacity: 0.5;transform: rotate(-15deg) scale(1);;}
}

/* RW 未开始券倒计时 样式 */
.RW-start-soon {
  --sui-color-promo: #FC4070;
  /*rw:begin*/
  color: var(--sui-color-promo, #FC4070);

  .coupon-countdown__txt {
    /*rw:begin*/
    color: var(--sui-color-promo, #FC4070);
  }
  .coupon-countdown__time {
    span {
      /*rw:begin*/
      background-color: var(--sui-color-promo, #FC4070);
    }
  }
}
</style>
